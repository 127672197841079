<template>
  <div class="signup-form">
    <form @submit.prevent="signupUser">
      <div class="form-group">
        <!--
            <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
        -->

       <!--
         <div class="form-control">
          <div class="label-container">
            <label class="signup-label">{{ $t("name") }}</label>
            <span class="required">*</span>
          </div>
          <input
            id="userName"
            type="text"
            v-model="userName"
            autocomplete="off"
            :placeholder="`${namePlaceholder}`"
            maxlength="32"
          />
        </div>
       -->
        
        <JhakaasInput 
          inputType="text"
          :label="$t('name')"
          inputId="userName"
          reference="userName"
          :value="userName"
          :helperText="formError"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          autocomplete="false"
          @keydown.space.prevent
          required
          @onChange="value => userName = value"
          />

        <!--
           <div class="form-control" v-if="inputtype === 'Mobile'">
          <div class="label-container">
            <label class="signup-label">{{ $t("mobilenumber") }}</label>
            <span class="required">*</span>
          </div>
          <div class="input-container">
            <input ref="mobileno" id="userMobileno" type="text" v-model="userMobileno" placeholder="mobilenumber" />
            <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
              <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
            </span>
          </div>
        </div>
        -->

        <JhakaasInput 
          v-if="inputtype === 'Mobile'"
          inputType="text"
          :label="$t('mobilenumber')"
          inputId="userMobileno"
          reference="mobileno"
          helperText=""
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          autocomplete="false"
          @keydown.space.prevent
          required
          disabled
          :value="userMobileno"
          @onChange="value => userMobileno = value"
          >
        <template
        v-slot:endIcon
        >
           <div
           style="color:white"
           >
           <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
              <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
            </span>
           </div>
        </template>
        </JhakaasInput>
        <!--

        <div class="form-control" v-else>
          <div class="label-container">
            <label class="signup-label">{{ $t("email id") }}</label>
            <span class="required">*</span>
          </div>
          <div class="input-container">
            <input
              ref="email"
              id="userEmail"
              :class="localDisplayLang === 'ara' ? 'py-l' : 'py-r'"
              type="email"
              v-model="userEmail"
              placeholder="Email"
              maxlength="64"
            />
            <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
              <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
            </span>
          </div>
        </div>
        -->

        <JhakaasInput 
         v-else
          inputType="text"
          :label="$t('email id')"
          inputId="userEmail"
          reference="email"
          helperText=""
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          autocomplete="false"
          @keydown.space.prevent
          required
          disabled
          :value="userEmail"
          @onChange="value => userEmail = value"
          >
        <template
        v-slot:endIcon
        >
           <div
          
           >
           <span
           class="change-btn-bg"
           :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
              <span 
              
              class="change-button" @click="changeFrom">{{ $t("change") }}</span>
            </span>
           </div>
        </template>
        </JhakaasInput>

        <!--
          <div class="form-control">
          <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <label class="signup-label">{{ $t("password") }}</label>
            <span class="required">*</span>
          </div>
          <div class="input-container" dir="ltr">
            <input
              id="userPassword"
              type="password"
              v-model="password"
              autocomplete="new-password"
              :placeholder="`${passwordPlaceholder}`"
              @keydown.space.prevent
            />
            <span class="eye">
              <img :src="eyeopen" @click="toggleEye($event)" alt />
            </span>
          </div>
        </div>
        -->

        <JhakaasInput 
        
          inputType="password"
          :label="$t('password')"
          inputId="userPassword"
          reference="password"
          :helperText="passwordError"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          autocomplete="false"
          @keydown.space.prevent
          required
          :value="password"
          @onChange="value => password = value"
          >
          <template
          v-slot:endIcon
          >
            <span class="eye">
              <img 
              style="width: 20px;"
              :src="eyeopen" @click="toggleEye($event)" alt />
            </span>
          </template>
        
        </JhakaasInput>
        

        <!-- For Captcha -->

        <div class="form-control">
          <div v-if="captchaImage && appConfig.featureEnabled.isCaptchaEnabled" class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <label class="signup-label label-color">{{ $t("Please enter the numbers you see in the image") }}</label>
          </div>
          <!-- <img v-if="captchaImage" class="captcha-image" :src="captchaImage" alt="Captcha" /> -->

          <div
            v-if="captchaImage && appConfig.featureEnabled.isCaptchaEnabled"
            :style="{
              backgroundImage: 'url(' + captchaImage + ')',
              'background-repeat': 'no-repeat',
              'background-color': 'white',
              width: '150px',
            }"
            class="captcha-image"
          ></div>
        </div>

        <div v-if="appConfig.featureEnabled.isCaptchaEnabled">
          <button @click="getAnotherCaptcha" class="button-link color-primary-hover" type="button">
            {{ $t("Click to refresh if you can not read") }}
          </button>
        </div>

        <!--
           <div v-if="appConfig.featureEnabled.isCaptchaEnabled" class="form-control">
          <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
          
          </div>
          <div class="input-container"  :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <input
              id="captcha"
              type="text"
              v-model="captcha"
              :placeholder="`${captchaPlaceholder}`"
              @keydown.space.prevent
            />
          </div>
        </div>
        -->

        <JhakaasInput 
        v-if="appConfig.featureEnabled.isCaptchaEnabled"
        inputType="text"
        :label="`${captchaPlaceholder}`"
        inputId="captcha"
        reference="captcha"
        :helperText="captchaError"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        autocomplete="false"
        @keydown.space.prevent
        required
        :value="captcha"
        @onChange="value => captcha = value"
        />
       

        <!-- <div class="form-control">
          <div class="label-container">
            <label class="signup-label">{{ $t('age') }}</label>
            <span class="required">*</span>
          </div>
        Show datepicker only if age range flag is set to false-->
        <!-- <date-picker
            v-if="!appConfig.featureEnabled.ageRange"
            v-model="dob"
            width="100%"
            format="YYYY-MM-DD"
            placeholder="yyyy-mm-dd"
            :not-after="new Date()"
            :disabled-date="(date) => date >= new Date()"
        ></date-picker>-->
        <!--Show Age range UI onnly if age range flag is set to true-->
        <!-- <div class="age-range" v-else>
            <div
              class="age-range-item"
              v-for="(item, index) in ageRange"
              :key="index"
              @click="selectAgeRange(item, index)"
            >
              <div>{{ item }}</div>
            </div>
          </div>
        </div>-->
        <!-- <div class="form-control">
          <label class="signup-label">{{ $t('gender') }}</label>
          <div class="radio">
            <input type="radio" id="Male" name="gender" value="MALE" v-model="gender" />
            <label for="Male">
              {{ $t('male') }}
              <span></span>
            </label>
            <input type="radio" id="Female" name="gender" value="FEMALE" v-model="gender" />
            <label for="Female">
              {{ $t('female') }}
              <span></span>
            </label>
            <input type="radio" id="Others" name="gender" value="OTHER" v-model="gender" />
            <label for="Others">
              {{ $t('others') }}
              <span></span>
            </label>
          </div>
        </div>-->
      </div>
        
        

<div class="signup-button">
        <!--
        :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
        -->
        <button
          class="button-primary signup"
          type="submit"
        >
          {{ $t("signup") }}
        </button>
      </div>
      <div class="sign-up-footer">
        <p>{{ $t("by signing up, you agree to") }}</p>
        <p>
          <span class="company-policies">
               <router-link tabindex="1" class="text" to="#" @click.native="showTermsOfUse">
              {{ $t("terms of use") }}
            </router-link>
            <!-- <a
              :href="
                localDisplayLang === 'ara'
                  ? appConfig.providerDetails.termsConditionsUrl.ar
                  : appConfig.providerDetails.termsConditionsUrl.default
              "
              target="_blank"
              >{{ $t("terms of use") }}</a
            > -->
            <!-- <router-link to="/TermsOfUse">
              <u @click="closeSignup">Terms of Use</u>
            </router-link>-->
          </span>
          {{ $t("and") }}
          <span class="company-policies">
              <router-link tabindex="1"  class="text" to="#" @click.native="showPrivacyPolicy">
              {{ $t("privacy policy") }}
            </router-link>
            <!-- <a
              :href="
                localDisplayLang === 'ara'
                  ? appConfig.providerDetails.privacyPolicyUrl.ar
                  : appConfig.providerDetails.termsConditionsUrl.default
              "
              target="_blank"
              >{{ $t("privacy policy") }}</a -->
            <!-- > -->
            <!-- <router-link to="/PrivacyPolicy">
              <u @click="closeSignup">Privacy Policy</u>
            </router-link>-->
          </span>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { _providerId } from "@/provider-config.js";

export default {
  props: ["input", "inputtype"],
  data() {
    return {
      userName: null,
      userEmail: null,
      userMobileno: null,
      password: null,
      dob: null,
      gender: null,
      formError: null,
      captchaError: "",
      passwordError: "",
      showDateFilter: false,
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      toast: null,
      ageRange: ["13-17", "18-30", "31-45", "45+"],
      providerUniqueId: _providerId,
      localDisplayLang: null,
      passwordPlaceholder: "",
      captchaPlaceholder: "",
      namePlaceholder: "",
      captcha: "",
      isActiveTerms:false,
      isActivePrivacy: false,
      captchaImage: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "gettoken", "appConfig", "getRtl", "country"]),
  },
  watch: {
    formError() {
      setTimeout(() => {
      
      this.formError = null;
      this.captchaError = "";
      this.passwordError = "";
    
      }, 5000);
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //adding age ranges to the array.
    if (this.appConfig.featureEnabled.kidsMode) {
      this.ageRange.unshift("<13");
    }
  },
  mounted() {
    this.isActivePrivacy = window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled;
    this.isActiveTerms = window.PROVIDER_SETUP_CONFIG.isTermsEnabled;
    

    if (this.appConfig.featureEnabled.isCaptchaEnabled) {
      // Get Captcha Image for display
      this.getCaptchaImage();
    }

    this.setupForm();
    this.passwordPlaceholder = this.$t("password");
    this.captchaPlaceholder = this.$t("Enter captcha");
    this.namePlaceholder = this.$t("name");
    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("social-captch-event", (data) => {
      

      let info = {
        data,
      };
      info.formType = "socialCaptcha";
      // this.formInfo = info;
      this.$emit("change", info);
    });

    //social signup.
    eventBus.$on("social-signup-response", (payload) => {
      

      let signup_type = payload.data.app;

      const browser = this.getPlatformType();

      let data = {
        sign_up_mode: signup_type === "FB" ? "Facebook" : signup_type === "Google" ? "Google" : "",
        status: "Success",
        email: "",
        mobile: "",
        platform: this.getPlatform(),
        platform_type: browser.browser,
      };

      // Firebase Analytics Events.
      this.SignupEvent(data);

      // Facebook Pixel Events
      this.faceBookCompleteRegistrationEvent(data);

      //clevertap signup event.
      this.signUpCleverEvent(data);
    });

    //Normal email & password signup.
    eventBus.$on("signup-response", (payload) => {
      let data = {};
      
      if (payload && payload.response && payload.response.reason) {
        this.formError = this.$t(payload.response.reason);

        //analytics event.
        data.sign_up_mode = this.inputtype === "Mobile" ? "Mobile" : "Email";
        data.status = "Failed";
        data.error_reason = payload.response.reason;
        // data.Subscriber_ID = payload.response.success;
        data.email = this.userEmail;
        data.mobile = this.userMobileno;
      } else {
        let template = {};
        // data.Subscriber_ID = payload.response.success;
        if (this.inputtype === "Mobile") {
          template.formType = "mobile";
          this.triggerSnackbarEvent();
          template.input = this.userMobileno;
          this.$emit("change", template);

          //analytics signup mobile event.
          data.sign_up_mode = "Mobile";
          data.status = "Success";
          data.mobile = this.userMobileno;
        } else if (this.inputtype === "Email") {
          template.formType = "lookup";
          template.input = this.userEmail;
          this.triggerSnackbarEvent();
          this.$emit("change", template);

          //analytics signup email event.
          data.sign_up_mode = "Email";
          data.status = "Success";
          data.email = this.userEmail;
        }

        const browser = this.getPlatformType();

        data.platform = this.getPlatform();

        data.platform_type = browser.browser;

        // Firebase Analytics Events.
        this.SignupEvent(data);

        // Facebook Pixel Events
        this.faceBookCompleteRegistrationEvent(data);

        let login_data = {
          Name: payload.data.subscribername ? payload.data.subscribername : "",
          Identity: payload.response.success ? payload.response.success : "",
          Email: payload.data.email ? payload.data.email : "",
          Phone: payload.data.mobileno ? payload.data.mobileno : "",
          Gender: payload.data.gender ? (payload.data.gender === "MALE" ? "M" : "F") : "",
          DOB: payload.data.dob ? moment(payload.data.dob).format("DD-MM-YYYY") : "",
          Photo: payload.data.picture ? payload.data.picture : "",
          "MSG-email": true,
          "MSG-push": true,
          "MSG-sms": true,
        };

        //clevertap user login sdk event.
        this.loginCleverEvent(login_data);

        //clevertap push user profile.
        // clevertap.profile.push({
        //   Site: {
        //     RegistrationStartDate: this.displayDateFormat()
        //   }
        // });

        //clevertap signup event.
        this.signUpCleverEvent(data);

        //branch signup event
        this.signupBranchEvent(data);
      }
    });
  },
  methods: {
    ...mapActions(["getCaptcha"]),

    getAnotherCaptcha() {
      this.getCaptchaImage();
    },
          showTermsOfUse() {
        let currentLanguage = this.getCurrentLanguageUrlBase();
        if(!window.PROVIDER_SETUP_CONFIG.isTermsEnabled){
          return;
        }

        this.$router.push({name: 'termsofuse', params: {lang: currentLanguage}});
    },
    showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if(!window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled){
        return;
      }

      this.$router.push({name: 'privacypolicy', params: {lang: currentLanguage}});

    },

    validatePassword(password) {
      this.passwordError = "";
      if (!password) {
        this.passwordError = this.$t("Password Required!");
        return false;
      } else if (password.length < 6) {
        this.passwordError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.passwordError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.captchaError = this.$t(data.reason);
            return;
          }

          

          this.captchaImage = data.payload;
          this.captchaReferenceId = data.referenceid;
        })
        .catch((error) => {
          
        });
    },

    setupForm() {
      if (this.input) {
        if (this.inputtype === "Mobile") {
          this.userMobileno = this.input;
         // this.$refs.mobileno.disabled = true;
          let input = document.getElementById("userMobileno");
          if(input === null || input === undefined) return;
          input.style.backgroundColor = "#5C5C5D";
          input.style.opacity = "0.3";
        } else {
          this.userEmail = this.input;
          //this.$refs.email.disabled = true;
          let input = document.getElementById("userEmail");
          if(input === null || input === undefined) return;

          input.style.backgroundColor = "#5C5C5D";
          input.style.opacity = "0.3";
        }
      }
    },
    changeFrom() {
      let info = {
        formType: "lookup",
      };
      this.$emit("change", info);
    },

    //select age range.
    selectAgeRange(item, index) {
      this.dob = null;
      let dom = document.getElementsByClassName("age-range-item");

      //removing styles from all the grid items.
      for (let i = 0; i < dom.length; i++) {
        dom[i].style.backgroundColor = "transparent";
        dom[i].style.color = "rgba(255,255,255,0.6)";
      }
      // dom.forEach(ele => {
      //   ele.style.backgroundColor = "transparent";
      //   ele.style.color = "rgba(255,255,255,0.6)";
      // });

      //applying styles for particular grid item.
      dom[index].style.backgroundColor = "#000000";
      dom[index].style.color = "#ffffff";

      

      switch (item) {
        case "<13":
          
          this.dob = this.getCurrentDate(10);
          break;
        case "13-17":
          
          this.dob = this.getCurrentDate(13);
          break;
        case "18-30":
          
          this.dob = this.getCurrentDate(18);
          break;
        case "31-45":
          
          this.dob = this.getCurrentDate(31);
          break;
        case "45+":
          
          this.dob = this.getCurrentDate(46);
          break;
        default:
          break;
      }
    },

    signupUser() {
      if (!this.validateName(this.userName) || !this.validateCountryCode()) return;

      if (this.inputtype === "Email") {
        if (
          !this.validateEmail(this.userEmail) ||
          !this.validatePassword(this.password)
          // || !this.validateDob(this.dob)
        )
          return;
      } else {
        if (
          !this.validateMobileno(this.userMobileno) ||
          !this.validatePassword(this.password)
          //|| !this.validateDob(this.dob)
        )
          return;
      }

      let payload = {
        devicetype: "PC",
        subscribername: this.userName,
        country: this.country.CountryCode,
      };

      if (this.captcha && this.appConfig.featureEnabled.isCaptchaEnabled) {
        payload.captcha = JSON.stringify({
          text: this.captcha,
          referenceid: this.captchaReferenceId,
        });
      } else if (!this.captcha && this.appConfig.featureEnabled.isCaptchaEnabled) {
        this.captchaError = this.$t("Please enter Captcha");
        return;
      }

      if (this.inputtype === "Mobile") {
        payload.mobileno = this.userMobileno;
      } else {
        payload.email = this.userEmail;
      }
      if (this.password) {
        payload.password = this.password;
      }
      if (this.dob) {
        if (!this.appConfig.featureEnabled.ageRange) {
          payload.dob = moment(new Date(this.dob)).format("YYYY-MM-DD");
        } else {
          payload.dob = this.dob;
        }
      }
      if (this.gender) {
        payload.gender = this.gender;
      }

      

      eventBus.$emit("subscriberSignup", payload);
    },

    redirectLookup() {
      let template = {};
      template.formType = "lookup";
      template.input = this.userEmail;
      
      this.$emit("change", template);
    },

    triggerSnackbarEvent() {
      let payload = {
        state: true,
        message:
          this.inputtype === "Email"
            ? "Verification link sent to your mail address"
            : "OTP has been sent to your mobile number",
        color: "green",
      };
      eventBus.$emit("snackBarMessage", payload);
    },

    validateDob(dob) {
      this.formError = null;
      if (!dob) {
        this.formError = $t("Please select age.");
        return false;
      } else if (dob) {
        let d = new Date();
        let currentYear = d.getFullYear();
        let userYear = moment(dob)
          .format("YYYY-MM-DD")
          .split("-")[0];
        if (currentYear - userYear >= 0) {
          if (currentYear - userYear < 13) {
            this.formError = "Age should be greater than 13";
            return false;
          }
        }
      }
      return true;
    },

    closeSignup() {
      this.$emit("closePopup");
    },
  },
  components: { DatePicker, 
    JhakaasInput: () => import("../../../utils/JhakaasInput.vue"),
  },
  mixins: [Utility, googleAnalytics, cleverTap, branchIO, facebookPixelAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./signup.scss"
</style>
