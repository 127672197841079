const facebookPixelActions = {
  data() {
    return {};
  },
  created() {},
  methods: {
    faceBookCompleteRegistrationEvent(data) {
      const params = {};

      let signupStatus;
      if (data.status === "Success") {
        signupStatus = true;
      } else {
        signupStatus = false;
      }

      params["registration_method"] = data.sign_up_mode;
      params["status"] = signupStatus;
      params["error_reason"] = data.error_reason ? data.error_reason : "";
      params["email"] = data.email ? data.email : "";
      params["mobile"] = data.mobile ? data.mobile : "";
      params["platform"] = data.platform ? data.platform : "";
      params["platform_type"] = data.platform_type ? data.platform_type : "";

      console.log("FB FINAL PARAMS", params);

      fbq("track", "CompleteRegistration", params);
    },

    faceBookSubscribeEvent(data) {
      const params = { ...data };

      delete params.currency_code;

      params["currency"] = params.currency_code;

      console.log("FB SUBSCRIBE FINAL PARAMS", params);

      fbq("track", "Subscribe", params);
    },

    faceBookInitateCheckoutEvent(data) {
      const params = { ...data };

      console.log("FB INITATE CHECKOUT FINAL PARAMS", JSON.stringify(params));

      fbq("track", "InitiateCheckout", params);
    },
  },
};

export default facebookPixelActions;
